import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DatePipe, KeyValue } from '@angular/common';
import { Configuration } from '../../app.constants';
import { MemberProfileService } from '../shared/services/member-profile.service';
import { ChangeHealthNetworkComponent } from '../change-health-network/change-health-network.component';
import { ChangeHealthNetworkService } from '../shared/services/change-health-network.service';
import { ProviderInfo } from '../shared/provider-info';
import { ChangePCPComponent } from '../change-pcp/change-pcp.component';
import { ProviderInfoSummary } from '../shared/provider-info-summary';
import { ProviderSearchService } from '../shared/services/provider-search.service';
import { ChangePCPChangeHealthNetworkService } from '../shared/services/change-pcp-change-health-network.service';
import { ProviderSearchParameter } from '../shared/provider-search-parameter';
import { MemberProfile } from '../shared/member-profile';
import { MemberPlanOperation } from '../shared/member-plan-operation';
import { ChangePCPChangeHealthNetworkSitecoreTemplate } from '../shared/change-pcp-change-health-network-sitecore-template';
import { ChangePCPService } from '../shared/services/change-pcp.service';
import { ChangeHnPcpRequest } from '../shared/change-pcp-request';
import { ChangePcpResponse } from '../shared/change-pcp-response';
import { ProviderService } from '../shared/services/provider.service';
import { HealthNetworkPlan } from '../shared/health-network-plan';
import { ChangePCPReasonCodes } from '../shared/change-pcp-reason-codes';
import { LanguageService, INotifyChange } from '../../core/shared/services/language.service';
import { combineLatest, Subscription } from 'rxjs';
// import { JsonError } from 'survey-angular';
import { Md5 } from 'ts-md5';
import { SubSink } from 'subsink';
import { environment } from "../../../environments/environment";
import { ExclusiveGroupMessage } from '../shared/change-pcp-exclusive-group-message';

declare var $: any;

@Component({
  templateUrl: 'change-pcp-change-health-network.component.html',
  styleUrls: ['change-pcp-change-health-network.component.scss'],
  providers: [ProviderSearchService, ChangePCPChangeHealthNetworkService,
    ChangeHealthNetworkService, ChangePCPService, ProviderService, DatePipe]
})

export class ChangePCPChangeHealthNetworkComponent implements OnInit, OnChanges, OnDestroy {

  changePCPComponent: ChangePCPComponent;
  healthnetwork = new HealthNetworkPlan();
  memberProfile: MemberProfile = new MemberProfile();
  provider: ProviderInfo = new ProviderInfo();
  memberPlanOperation: MemberPlanOperation = new MemberPlanOperation();
  changePCPChangeHealthNetworkSitecoreTemplate: ChangePCPChangeHealthNetworkSitecoreTemplate
    = new ChangePCPChangeHealthNetworkSitecoreTemplate();
  changeHealthNetworkComponent: ChangeHealthNetworkComponent;
  changePcpResponse: ChangePcpResponse = new ChangePcpResponse();
  selectedProvider: ProviderInfoSummary = new ProviderInfoSummary();
  providerInfo: ProviderInfoSummary[];
  providerFilter = new ProviderSearchParameter();
  effectiveDate: Date = new Date();
  changeHnPcpRequest: ChangeHnPcpRequest = new ChangeHnPcpRequest();

  isCurrentPCPInNetwork: boolean;
  isHealthNetworkChanged: boolean = false;
  isKaiserSelected: boolean;
  isAllowedToChangePCPWithoutHN: boolean = false;
  isSubmit: boolean = false;
  selectedKaiser12Months: boolean = null;
  selectedKaiserFriendsFamily: boolean = null;
  showSearchForProvider: boolean;
  showSearch: boolean;
  showSearchResults: boolean;
  showButtons: boolean = true;
  showPCPOptionsDropDown = false;
  disableSubmitButton: boolean = true;
  collapseSearchPanel: boolean = true;
  showCollapsibleButton: boolean = false;
  loading: boolean = false;


  message: string;
  secondaryMessage: string;
  selectedPCPOption: string;
  error: string = '';
  isMedcalMemberOutsideCCN: boolean = false;
  isExclusiveHealthNeworkPlan: boolean = false;
  selectedExclusiveMessage: ExclusiveGroupMessage;
  isReadOnly: boolean = false;
  listOfReasonCodes: Array<ChangePCPReasonCodes> = new Array<ChangePCPReasonCodes>();
  listOfExclusiveMessagesSitecore: Array<ExclusiveGroupMessage> = new Array<ExclusiveGroupMessage>();
  listOfReasonCodesSitecore: any = null;
  selectedReasonForChange: any = '-1';
  otherReasonForChange: string = '';
  showEffectiveDate: Date = new Date();
  changePCPNote: string = '';
  referenceIDPreviouslySubmitted: string;
  private subscriptions = new SubSink();
  private subscriptionLanguage: Subscription = new Subscription();
  private subscriptionMemberProfile: Subscription = new Subscription();

  constructor(
    public configuration: Configuration,
    private memberProfileService: MemberProfileService,
    private providerSearchService: ProviderSearchService,
    private changePCPChangeHealthnetworkService: ChangePCPChangeHealthNetworkService,
    private providerService: ProviderService,
    private languageService: LanguageService
  ) { }

  ngOnChanges(change: SimpleChanges) {
  }

  ngOnInit() {
    try {
      
      this.subscriptions.sink = combineLatest([
        this.languageService.notifyObservable$,
        this.memberProfileService.notifyObservable$
      ]).subscribe(result => {
          this.memberProfile = this.memberProfileService.memberProfile;
          if (this.memberProfile) this.onLoad();
        });

    } catch (ex) {
      throw ex;
    }
  }
  
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private onLoad(): void {
    this.listOfReasonCodesSitecore = null;
    this.getChangePcpReasonCodesSitecore();
    this.getChangePCPChangeHealthNetworkContent();
    this.getMemberProfileAndMemberPlanOperation();
    this.getChangePCPReasonCodes();
    this.getChangePCPExclusiveMessagesSitecore();

    this.setSubmitChangesButton();
    this.setDefaultHealthNetwork();
    window.scrollTo(0, 0);
  }

  private setDefaultHealthNetwork() {
    this.healthnetwork.HealthNetworkCode = this.memberProfile.HealthNetworkPlan.HealthNetworkCode;
    this.healthnetwork.HealthNetworkPlanCode = this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode;
    this.healthnetwork.HealthNetworkPlanDisplayText
      = this.memberProfile.HealthNetworkPlan.HealthNetworkPlanDisplayText + ' ' + this.configuration.CurrentValue;

  }

  private getMemberProfileAndMemberPlanOperation() {
    this.loading = true;
    const today: Date = new Date();

    this.memberProfile = this.memberProfileService.memberProfile;
    this.calculateChangePCPEffectiveDate();

    this.showEffectiveDate = this.effectiveDate;

    this.referenceIDPreviouslySubmitted = this.getPreviousSubmittedReferenceID(Md5.hashStr(this.memberProfile.CIN));

    if (this.referenceIDPreviouslySubmitted) {
      this.showEffectiveDate = undefined;
      this.isReadOnly = true;
    } else {
      if (this.memberProfile.ChangePCPEffectiveDate !== null) {
        const changePCPEffectiveDate = new Date(this.memberProfile.ChangePCPEffectiveDate.toString());
        if (changePCPEffectiveDate >= today) {
          this.isReadOnly = true;
        }
      }
    }
    this.getMemberPlanOperation();
    this.loading = false;
  }

  private setupUI() {
    if (this.isAllowedToChangePCPWithoutHN) {
      this.showSearch = false;
      this.selectedPCPOption = '-1';
      this.showPCPOptionsDropDown = true;
    }
    else {
      this.showSearch = true;
      this.selectedPCPOption = '-1';

      if (this.memberProfile.HealthNetworkPlan.HealthNetworkCode
        === this.healthnetwork.HealthNetworkCode) {
        this.showPCPOptionsDropDown = false;
      } else {
        this.showPCPOptionsDropDown = true;
      }

    }
  }

  private getMemberPlanOperation() {
    this.memberProfileService.getMemberPlanAllowedOperation()
      .subscribe(
        response => {
          this.memberPlanOperation = response;
          if (!this.memberProfile || !this.memberProfile.HealthNetworkPlan) return;

          //this.getExclusiveGroupProps();          

          if (this.configuration.MediCalHealthNeworksAllowedToChangePCPWithoutHN.indexOf(this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode) !== -1 ||
            this.memberProfile.LOB.LOBCode == this.configuration.OneCareLOBCode || this.memberProfile.LOB.LOBCode === this.configuration.OneCareConnectLOBCode) {
            this.isAllowedToChangePCPWithoutHN = true;
          }
          else {
            this.isAllowedToChangePCPWithoutHN = false;
          }

          if (this.configuration.MediCalHealthNeworksAllowedToChangePCPWithoutHN.indexOf(this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode) === -1 &&
            this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode) {
            this.isMedcalMemberOutsideCCN = true;
          } else {
            this.isMedcalMemberOutsideCCN = false;
          }
          this.setupUI();
        },
        error => {
          this.error = <any>error;
        });
  }

  private getExclusiveGroupProps() {
    // Check if selected HealthNetwork Plan is exclusive one
    
    const selectedHNPlanCode = this.memberProfile.HealthNetworkPlan.HealthNetworkPlanCode;
    this.isExclusiveHealthNeworkPlan = environment.filterRulesHN.some((r) => selectedHNPlanCode.endsWith(r.suffix));

    // console.log('Member HN Plan:', selectedHNPlanCode);
    // console.log('HN data:', this.memberProfile.HealthNetworkPlan);

    // Find the matching plan    
    this.selectedExclusiveMessage = Object.values(this.listOfExclusiveMessagesSitecore).find(
      (message: any) => selectedHNPlanCode.endsWith(message.Suffix)
    );
    
  }

  private getChangePCPChangeHealthNetworkContent() {
    this.changePCPChangeHealthnetworkService.getChangePCPChangeHealthNetworkSitecore()
      .subscribe(
        response => {
          this.changePCPChangeHealthNetworkSitecoreTemplate = response;
        },
        error => {
          this.error = <any>error;
        });
  }

  healthNetworkChanged(healthNetworkCode) {
    this.healthnetwork = healthNetworkCode;
    
    // Sets EffectiveDate for special HN if selected
    this.calculateConditionalEffectiveDate();

    if (
      !this.checkIsKaiserSelected()
      && 
      healthNetworkCode.HealthNetworkCode !== this.memberProfile.HealthNetworkPlan.HealthNetworkCode) {
      this.checkIfCurrentPCPInNetwork();
      if (this.isCurrentPCPInNetwork) {
        this.showSearch = false;
        this.changePCPOptionChanged('-1');
      }
      else {
        this.resetChangePCP();
      }

    } else {
      this.resetChangePCP();
    }

    if (healthNetworkCode.HealthNetworkCode !== this.memberProfile.HealthNetworkPlan.HealthNetworkCode) {
      this.isHealthNetworkChanged = true;
    }
    else {
      this.isHealthNetworkChanged = false;

      if (this.isMedcalMemberOutsideCCN ||
        (this.memberProfile.LOB.LOBCode === this.configuration.OneCareConnectLOBCode) ||
        (this.memberProfile.LOB.LOBCode === this.configuration.OneCareLOBCode)) {
        this.disableSubmitButton = true;
      } else {
        this.disableSubmitButton = false;
      }

      this.setupUI();
    }
    this.setSubmitChangesButton();
  }

  checkIsKaiserSelected(): boolean {
    if ((this.healthnetwork?.HealthNetworkPlanCode?.toLocaleUpperCase() === this.configuration?.KaiserCodeRegular)
      || (this.healthnetwork?.HealthNetworkPlanCode?.toLocaleUpperCase() === this.configuration?.KaiserCodeExpansion)
    ) {
      this.isKaiserSelected = true;
    }
    else {
      this.isKaiserSelected = false;
    }
    return this.isKaiserSelected;
  }

  currentPCPInNetworkChanged() {
    this.changePCPComponent.isCurrentPCPInNetwork = this.isCurrentPCPInNetwork;
  }

  setProviderFilter(pProviderFilter: ProviderSearchParameter) {
    this.providerFilter = pProviderFilter;
    this.providerFilter.LOB = this.memberProfile.LOB.LOBCode;

    if (this.healthnetwork.HealthNetworkPlanCode !== undefined) {
      this.providerFilter.CSPI_ID = this.healthnetwork?.HealthNetworkPlanCode;
    }
    else {
      this.providerFilter.CSPI_ID = this.memberProfile?.HealthNetworkPlan?.HealthNetworkPlanCode;
    }

    this.providerFilter.AcceptingNewPatients = 'Y';
    this.providerFilter.MemberGender = this.memberProfile.Gender;
    this.providerFilter.MemberBirthDate = new Date(this.memberProfile.DateOfBirth);
    this.providerFilter.UserProximity = true;
    this.providerFilter.CSCS_ID = this.memberProfile.HealthNetworkClassCode;
    this.providerFilter.Network = this.memberProfile.HealthNetworkPlan.HealthNetworkCode;
  }

  onSearchForAProvider(providerFilter: ProviderSearchParameter) {
    this.setProviderFilter(providerFilter);
    this.searchForAProvider(providerFilter);
    this.collapseSearchPanel = true;
    this.showCollapsibleButton = true;
    this.showButtons = true;
    this.showSearchResults = true;
    this.selectedProvider = new ProviderInfoSummary();
  }

  searchForAProvider(providerFilter: ProviderSearchParameter) {

    try {
      this.loading = true;
      // Add the default values for provider search
      this.providerSearchService.searchForProviders(providerFilter)
        .subscribe(
          response => {
            this.providerInfo = response;
            this.providerInfo.forEach(provider => provider.Selected = false);
            this.loading = false;
          },
          error => {
            this.error = <any>error;
            this.loading = false;
          });

    } catch (error) {
      throw error;
    }
  }

  checkIfCurrentPCPInNetwork() {
    try {
      this.loading = true;
      const providerFilter = new ProviderSearchParameter();
      this.setProviderFilter(providerFilter);
      this.providerFilter.ProviderId = this.memberProfile.PrimaryCareProviderInfo.ProviderId;
      if (!this.providerFilter.ProviderId) {
        this.isCurrentPCPInNetwork = false;
        this.resetChangePCP();
      }
      else {
        // Add the default values for provider search
        this.providerSearchService.searchForProviders(providerFilter)
          .subscribe(
            response => {
              this.providerInfo = response;
              this.providerInfo.forEach(provider => provider.Selected = false);
              if (response.length > 0) {
                this.isCurrentPCPInNetwork = true;
                this.showSearch = false;
                this.changePCPOptionChanged('-1');
              }
              else {
                this.isCurrentPCPInNetwork = false;
                this.resetChangePCP();
              }
              this.loading = false;
            },
            error => {
              this.error = <any>error;
              this.loading = false;
            });
      }

      this.loading = false;

    } catch (error) {
      throw error;
    }
  }

  resetChangePCP() {
    this.collapseSearchPanel = false;
    this.showSearch = true;
    this.showSearchResults = false;
    this.showButtons = true;
    if (this.isCurrentPCPInNetwork) {
      this.changePCPOptionChanged('-1');
      this.showPCPOptionsDropDown = true;
    }
    else {
      this.changePCPOptionChanged(this.configuration.ChangePCPOption);
      this.showPCPOptionsDropDown = false;
    }

  }

  onCancel(afterSave: boolean = false) {
    this.selectedProvider = new ProviderInfoSummary();
    if (this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode && !this.isAllowedToChangePCPWithoutHN) {
      this.showSearch = false;
      this.showSearchResults = false;
      this.selectedPCPOption = '-1';
      this.showPCPOptionsDropDown = false;
    }
    else {
      this.collapseSearchPanel = false;
      this.showSearch = false;
      this.showSearchResults = false;
      this.showButtons = true;
      this.selectedPCPOption = '-1';
      this.showPCPOptionsDropDown = true;
      this.isCurrentPCPInNetwork = false;
      this.setSubmitChangesButton();
    }
    this.healthnetwork = this.memberProfile.HealthNetworkPlan;
    this.selectedReasonForChange = '-1';
    this.otherReasonForChange = '';
    window.scrollTo(0, 0);
    if (!afterSave) {
      this.message = '';
    }
    this.error = '';
  }

  setSelectedOption() {
    this.isCurrentPCPInNetwork = true;
    if (this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
      !this.isAllowedToChangePCPWithoutHN) {
      this.changePCPOptionChanged(this.configuration.KeepCurrentPCPOption);
    }
    else {
      this.changePCPOptionChanged('-1');
    }
  }

  onClick() {
    this.message = '';
    this.error = '';
    if (this.selectedReasonForChange !== 'other') {
      this.changeHnPcpRequest.ReasonForChange = this.selectedReasonForChange.Code
        + ';' + this.selectedReasonForChange.DisplayText;
    } else {
      if (this.otherReasonForChange && this.otherReasonForChange.trim().length > 0) {
        this.changeHnPcpRequest.ReasonForChange = 'CO69;' + this.otherReasonForChange;
      }
      else {
        return;
      }
    }

    this.providerService.submitChangePcp(this.changeHnPcpRequest)
      .subscribe(response => {
        this.changePcpResponse = response;

        this.memberProfile.ChangePCPEffectiveDate = this.effectiveDate;
        if (!this.isKaiserSelected) {
          this.message = this.changePCPChangeHealthNetworkSitecoreTemplate.SuccessMessageText;
          this.secondaryMessage
            = this.changePCPChangeHealthNetworkSitecoreTemplate.SecondarySuccessMessageText.replace(this.configuration.FXIRequestIDToken, this.changePcpResponse.CustomerServiceRequestNote.RequestId);
          this.isReadOnly = true;
          this.showEffectiveDate = this.effectiveDate;
        }

        //Kaiser or partial success - no closed status.
        if (this.changePcpResponse.CustomerServiceRequestNote.RequestNotes.includes(this.configuration.FXIPartialSystemError)
          || this.isKaiserSelected) {
          //store reference to support restriction for a day. 
          this.storeSubmittedReferenceID(this.changePcpResponse.CustomerServiceRequestNote.RequestId);
          this.message
            = this.changePCPChangeHealthNetworkSitecoreTemplate.KaiserSuccessMessage.replace(this.configuration.FXIRequestIDToken, this.changePcpResponse.CustomerServiceRequestNote.RequestId);
          this.secondaryMessage = '';
          this.showEffectiveDate = undefined;
          this.isMedcalMemberOutsideCCN = false;
          this.isReadOnly = true;
        }

        this.resetChangePCP();
        this.isCurrentPCPInNetwork = false;
        this.onCancel(true);
        this.isSubmit = true;
      },
        error => {
          this.error = this.changePCPChangeHealthNetworkSitecoreTemplate.ErrorMessage;
          this.message = '';
          this.isMedcalMemberOutsideCCN = false;
        });
    window.scrollTo(0, 0);
  }

  onSelectedProviderChange(provider: ProviderInfoSummary) {
    this.selectedProvider = provider;
    if (this.selectedProvider) {
      this.selectedProvider.Selected = true;

      this.changeHnPcpRequest = new ChangeHnPcpRequest();
      this.changeHnPcpRequest.ProviderId = this.selectedProvider.ProviderId;
      if (this.selectedPCPOption === this.configuration.KeepCurrentPCPOption) {
        this.changeHnPcpRequest.HealthNetworkPlanCode = this.memberProfile.HealthNetworkPlan.HealthNetworkCode;

      }
      else {

        this.changeHnPcpRequest.HealthNetworkPlanCode = this.healthnetwork.HealthNetworkPlanCode;
      }
      this.changeHnPcpRequest.ProviderName = this.selectedProvider.ProviderName;
      this.changeHnPcpRequest.ChangeEffectiveDate = this.effectiveDate;
    }

    this.setSubmitChangesButton();
  }

  calculateChangePCPEffectiveDate() {
    this.effectiveDate = this.memberProfile.CalculatedChangePCPEffectiveDate;
  }

  calculateConditionalEffectiveDate() {
    const currDate = new Date();
    const selectedPlanCode = this.healthnetwork.HealthNetworkPlanCode;
    const filterArray = environment.exclusionMapHN;
    // console.log('Selected PlanCode:', selectedPlanCode);

    for (const filterItem of filterArray) {
      const [cspiId, hnId, filterDate, isShownAfter, effectEndDate, effectDate] = filterItem.split('|');
      const isPlanMatch = cspiId ? selectedPlanCode === cspiId : false;
      const isDateMatch = effectDate ? currDate < new Date(effectEndDate) : false;

      // check if both conditions match, set the effective date and return
      if (isPlanMatch && isDateMatch) {
        const utcTime = new Date(effectDate).toISOString().replace('.000Z', '');
        // console.log('utcTime:', utcTime); //2025-01-01T00:00:00; 2025-01-01T00:00:00.000Z
        this.effectiveDate = new Date(utcTime) || null;
        return;
      }
    }
    this.calculateChangePCPEffectiveDate();
  }

  onResetForm() {
    this.changePCPComponent.selectedOption = this.selectedPCPOption;
    this.healthNetworkChanged(this.memberProfile.HealthNetworkPlan.HealthNetworkCode);
    this.message = '';
    this.error = '';
    window.scrollTo(0, 0);
  }

  changePCPOptionChanged(newValue) {
    this.selectedPCPOption = newValue;
    if (newValue === this.configuration.ChangePCPOption) {
      this.showSearch = true;
      this.showButtons = false;
      this.showPCPOptionsDropDown = false;
      this.showSearchResults = false;
      this.collapseSearchPanel = false;
    } else if (newValue === this.configuration.KeepCurrentPCPOption) {
      this.showSearch = false;
      this.showButtons = true;
      this.showPCPOptionsDropDown = true;
      this.collapseSearchPanel = true;
      this.showSearchResults = false;
      this.setKeepCurrentPCP();
    } else if (newValue === this.configuration.KaiserOption) {
      this.showSearch = false;
      this.showButtons = true;
      this.showPCPOptionsDropDown = false;
      this.collapseSearchPanel = true;
      this.showSearchResults = false;
    }
    else {
      this.showSearch = false;
      this.showButtons = true;
      this.showPCPOptionsDropDown = true;
      this.collapseSearchPanel = true;
      this.showSearchResults = false;
    }
    this.providerInfo = new Array<ProviderInfoSummary>();
    this.setSubmitChangesButton();
  }

  setKeepCurrentPCP() {
    this.changeHnPcpRequest = new ChangeHnPcpRequest();
    this.changeHnPcpRequest.ProviderId = this.memberProfile?.PrimaryCareProviderInfo?.ProviderId;
    this.changeHnPcpRequest.HealthNetworkPlanCode = this.healthnetwork?.HealthNetworkPlanCode;
    this.changeHnPcpRequest.ProviderName = this.memberProfile?.PrimaryCareProviderInfo?.ProviderName;
    this.changeHnPcpRequest.ReasonForChange = this.configuration.EmptyString;
    this.changeHnPcpRequest.ChangeEffectiveDate = this.effectiveDate;
  }

  // This method determines if the Submit Button should be disabled or not
  setSubmitChangesButton() {
    if (this.selectedPCPOption !== undefined) {
      if (this.selectedPCPOption === this.configuration.KaiserOption &&
        !this.ifKaiserMediCalAnd12MonthsFriendsFamilySelected()
      ) {
        this.disableSubmitButton = true;
      } else {

        if (this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode &&
          !this.isAllowedToChangePCPWithoutHN) {
          if ((this.selectedPCPOption === '-1' && this.isHealthNetworkChanged === false) ||
            (this.selectedPCPOption === '-1' && this.isHealthNetworkChanged === true)) {
            this.disableSubmitButton = true;
          }
          else {
            this.disableSubmitButton = false;
          }
        }

        if (this.isAllowedToChangePCPWithoutHN) {
          if (this.selectedPCPOption === '-1' || this.isHealthNetworkChanged === false) {
            this.disableSubmitButton = true;
          }
          else {
            this.disableSubmitButton = false;
          }
        }

        if (this.selectedPCPOption === this.configuration.ChangePCPOption) {
          if (!this.selectedProvider || this.selectedProvider.Selected === false) {
            this.disableSubmitButton = true;
          } else {
            this.disableSubmitButton = false;
          }
        }
      }
    }
    else {
      this.disableSubmitButton = true;
    }
  }

  // Emitted from the ChangeHealthNetworkComponent
  // This event is raised when members pick and chose
  // through the Kaiser drop downs when the member chooses Kaiser.
  kaiserEvent($event) {
    this.selectedKaiser12Months = $event.selectedKaiser12Months;
    this.selectedKaiserFriendsFamily = $event.selectedKaiserFriendsFamily;
    this.changePCPNote = $event.changePCPNote;

    if (this.ifKaiserMediCalAnd12MonthsFriendsFamilySelected()) {
      this.changeHnPcpRequest = new ChangeHnPcpRequest();
      this.changeHnPcpRequest.NoteForChangePCP = $event.changePCPNote;
      this.changeHnPcpRequest.HealthNetworkPlanCode = this.healthnetwork.HealthNetworkPlanCode;
      this.changeHnPcpRequest.ProviderId = this.memberProfile.PrimaryCareProviderInfo.ProviderId;
      this.changeHnPcpRequest.ChangeEffectiveDate = this.effectiveDate;
      this.selectedPCPOption = this.configuration.KaiserOption;
    }

    this.changePCPOptionChanged(this.configuration.KaiserOption);


  }

  ifKaiserMediCalAnd12MonthsFriendsFamilySelected(): boolean {
    if (this.checkIsKaiserSelected() &&
      this.memberProfile.LOB.LOBCode === this.configuration.MediCalLOBCode) {
      if (
        (this.selectedKaiser12Months === true)
        || (this.selectedKaiser12Months === false && this.selectedKaiserFriendsFamily === true && this.changePCPNote)
      ) {
        return true;
      }
    }

    return false;
  }

  getChangePCPReasonCodes() {
    this.changePCPChangeHealthnetworkService.getChangePcpReasonCodes().subscribe(
      response => {
        this.listOfReasonCodes = response;
      },
      error => {
        this.error = this.changePCPChangeHealthNetworkSitecoreTemplate.ErrorMessage;
        this.message = '';
      });
  }

  getChangePcpReasonCodesSitecore(){
    this.changePCPChangeHealthnetworkService.getChangePcpReasonCodesSitecore()
      .subscribe(
        response => {
          this.listOfReasonCodesSitecore = response;
        },
        error => {
          this.error = <any>error;
        });
  }

  getChangePCPExclusiveMessagesSitecore() {
    this.changePCPChangeHealthnetworkService.getChangePCPExclusiveMessagesSitecore()
      .subscribe(
        response => {
          this.listOfExclusiveMessagesSitecore = response;
          this.getExclusiveGroupProps();
        },
        error => {
          this.error = <any>error;
        }
      );
  }

  private storeSubmittedReferenceID(requestID: string) {
    var today = new Date().toISOString().substring(0, 10);
    var uniqId = Md5.hashStr(this.memberProfile.CIN);

    var dataStr: any = localStorage.getItem(this.configuration.ChangeHnPcpResponseMessageStorageName);
    var data: Array<any> = JSON.parse(dataStr);
    if (data == null) {
      data = new Array<any>();
    }
    else {
      data = data.filter(d => { return d.id == today });
    }

    data.push({ id: today, uniqId: uniqId, refId: requestID })
    localStorage.setItem(this.configuration.ChangeHnPcpResponseMessageStorageName, JSON.stringify(data));
  }

  private getPreviousSubmittedReferenceID(account: string | Int32Array) {
    let today = new Date().toISOString().substring(0, 10);
    let dataStr: any = localStorage.getItem(this.configuration.ChangeHnPcpResponseMessageStorageName);
    var data: Array<any> = JSON.parse(dataStr);
    if (data != null) {
      return data.filter(d => { return d.id == today && d.uniqId == account })
        .map(d => d.refId).shift();
    }
  }
}
